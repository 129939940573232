import { useContext, useState } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import { Button, IconButton, InputAdornment, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../../../Footer";
import AppContext from "../../../AppContext";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { makeStyles } from "@mui/styles";
import Header from "../../../Header";
import { loginService } from "../../services/municipio_service";
import  jwtDecode from "jwt-decode";
import { useSearchParams, createSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    opacity: 0,
    "&.visible": {
      transition: "opacity 0.5s ease-in-out",
      opacity: 1,
    },
  },
  button: {
    opacity: 1,
    transition: "opacity 0.5s ease-in-out, transform 0.4s ease-in-out",
    "&.hidden": {
      transition: "opacity 0.5s ease-in-out",
      opacity: 0,
      pointerEvents: "none",
    },
    "&:hover": {
      transition: "opacity 0.5s ease-in-out, transform 0.4s ease-in-out",
      transform: "scale(1.2)",
    },
  },
}));
export default function Login() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { userHeader, setUserHeader, setToken, roles, setRoles } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const query_params = Object.fromEntries([...searchParams]); 
  const classes = useStyles();
  const [formVisible, setFormVisible] = useState(false);

  const handleClick = () => {
    setFormVisible(true);
  };

  function handleClickShowPassword() {
    return setShowPassword(!showPassword);
  }
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();
  // let usernames = {
  //   pablo: "pabl0123#",
  //   agforest: "agf0rest.?",
  //   bureau_veritas: "bur34u_v3r1t4s?",
  // };
  const login = () => {
    loginFunction();
  };
  const loginFunction = async () => {
   // console.log("login request");
    let response = await loginService(username, password);
   // console.log("login response ", response);
    if (response === false || !response.data.hasOwnProperty("token")) {
      Notiflix.Notify.warning("Usuario o contraseña incorrectos");
      return;
    }
    setToken("Bearer " + response.data["token"]);
    const decoded_raw = jwtDecode(response.data["token"]);
    
    const decoded = JSON.parse(decoded_raw["payload"])
   // console.log("Decoded JWT", decoded)
    if("roles" in decoded){
      let new_roles = []
      for(let rol of decoded["roles"]){
        new_roles.push(rol["name"])
      }
     // console.log("Estableciendo nuevos roles", new_roles)
      setRoles(new_roles)
    }
    try {
      let payload = JSON.parse(response.data["payload"]);
      setUserHeader(payload["email"]);
    } catch (err) {}
    navigate({pathname : "/main", search:createSearchParams(query_params).toString() });

    //navigate("/main");
    // if (username in usernames && usernames[username] === password) {
    //   navigate("/main");
    //   return;
    // }
  };
  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        maxWidth: "100vw",

        // maxHeight: "100vh",
      }}
    >
      <Header
        className=" login_header"
        style={{
          backgroundColor: "transparent",
          position: "fixed",
          zIndex: "50",
          border: "none",
          boxShadow: "none",
        }}
        showHelpButton={false}
      />
      <form
        // onSubmit={() => login()}
        className={
          formVisible
            ? "background_map form_container form_limits"
            : "background_map form_container"
        }
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            zIndex: 10,
            left: "0rem",
            width: "auto",
            height: "auto",
            minWidth: "100vw",
            minHeight: "100vh",
            overflow: "hidden",
            filter: "brightness(0.7)",
          }}
        >
          <div
            id="element-background-color"
            style={{
              position: "absolute",
              top: 0,
              zIndex: 11,
              left: "0rem",
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(var(--color-primary))",
              opacity: "0.40",
            }}
          ></div>
          <video
            className="background"
            loop
            autoPlay={true}
            playsinline
            muted
            poster="video/realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.png"
          >
            <source
              src={
                isMobile
                  ? "video/low_code_realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.mp4"
                  : "video/low_code_realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.mp4"
              }
              type="video/mp4"
            />
            <source
              src={
                isMobile
                  ? "video/low_code_realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.mp4"
                  : "video/low_code_realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.mp4"
              }
              type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>

          <img
            className="background"
            style={{ zIndex: "1" }}
            src="video/realistic-animation-of-a-satellite-in-orbit-of-pla-2022-08-04-14-26-32-utc.png"
          />
        </div>
        <div
          className="absolute-container"
          style={{ zIndex: formVisible ? 0 : 50 }}
        >
          <div className="first_row_container" style={{}}>
            <Button
              className={`${classes.button} ${formVisible ? "hidden" : ""}`}
              style={{
                zIndex: formVisible ? 0 : 500,
                backgroundColor: "rgb(var(--color-secondary))",
                fontWeight: 600,
                color: "var(--color-fourth)",
                padding: "3rem 6rem",
                fontSize: "2rem",
                height: "3rem",
                borderRadius: "25% / 45%",
                textTransform: "none ",
                boxShadow: "10px 10px 20px var(--color-fourth)",
                // position: "absolute",
                // left: isMobile ? "auto" : "20vw",
                // width: isMobile ? "" : "",
                // top: isMobile ? "25vh" : "49vh",
              }}
              onClick={handleClick}
            >
              login
            </Button>
            {!formVisible && (
              <div
                className="right_text_container text_container white_text"
                style={{ zIndex: formVisible ? 0 : 1000 }}
              >
                <div className="div-noBlur">
                  <label
                    className="big_label  div-noBlur"
                    style={{ fontSize: "2.6rem" }}
                  >
                    Building a sustainable future with geospatial datascience
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: "1rem",
                      placeContent: "center",
                      fontWeight: 100,
                    }}
                  >
                    space{"  "} tech{"  "}company
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <Paper
          className={` form_paper ${classes.paper} ${
            formVisible ? "visible" : ""
          }`}
          sx={
            {
              // ".MuiPaper-root": {
              //   backdropFilter: "opacity(30%)",
              // },
            }
          }
          style={{
            // maxWidth: "min(calc(var(--max-article-width) * 1 / 3), 80vw)",
            // minWidth: "220px",
            // width: "100%",
            // display: "flex",
            backgroundColor: "rgba(var(--color-primary) / 0.7)",
            color: "var(--color-fith)",
            // backdropFilter: "opacity(30%)",
            // padding: "3rem",
            // alignItems: "center",
            // placeContent: "center",
            // flexDirection: "column",
            // gap: "10px",
            // zIndex: 20,
          }}
        >
          <label>
            <h2>Inicio de sesión</h2>
          </label>
          <TextField
            value={username}
            style={{ width: "100%" }}
            sx={{
              "& p": { color: "var(--color-fith)" },
              ".MuiInputBase-input": {
                backgroundColor: "var(--color-fith)",
              },
            }}
            onChange={(e) => {
              setusername(e.target.value);
              setUserHeader(e.target.value);
            }}
            helperText={"Nombre de usuario"}
          />
          <TextField
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            value={password}
            helperText={"Contraseña"}
            variant="filled"
            sx={{
              // "& p": { color: "var(--color-fith)" },
              ".MuiFilledInput-root": {
                backgroundColor: "var(--color-fith)",
                "&:focus": {
                  backgroundColor: "var(--color-fith)",
                },
                "&.Mui-focused": {
                  backgroundColor: "white", // Color de fondo en enfoque activo (focus)
                },
              },
              ".MuiInputBase-root": {
                backgroundColor: "var(--color-fith)",
                borderRadius: "0.1rem",
                // "&.hover": {
                //   backgroundColor: "var(--color-fith) !important",
                // },
                "&:focus": {
                  backgroundColor: "var(--color-fith)",
                },
                "&.Mui-focused": {
                  backgroundColor: "white", // Color de fondo en enfoque activo (focus)
                },
              },

              ".MuiInputBase-adornedEnd": {
                backgroundColor: "var(--color-fith)",
                "&:hover": {
                  backgroundColor: "rgb(var(--color-secondary))",
                },
                "&:focus": {
                  backgroundColor: "rgb(var(--color-secondary))",
                },
                "&.Mui-focused": {
                  backgroundColor: "white", // Color de fondo en enfoque activo (focus)
                },
              },
              // ".MuiInputAdornment-root:hover": {
              //   backgroundColor: "white",
              // },
              // "&.hover": {
              //   backgroundColor: "white",
              // },
              ".MuiFormHelperText-root": {
                color: "var(--color-fith) !important",
              },
            }}
            style={{ width: "100%" }}
            onChange={(e) => {
              setpassword(e.target.value);
            }}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    // sx={{
                    //   "&.hover": {
                    //     backgroundColor: "rgb(var(--color-secondary))",
                    //   },
                    // }}
                  >
                    {showPassword ? (
                      <Visibility
                        style={{ color: "rgb(var(--color-primary))" }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ color: "rgb(var(--color-primary))" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={(ev) => {
              login();
            }}
            //type="submit"
            style={{
              backgroundColor: "rgb(var(--color-secondary))",
              fontWeight: 600,
              width: "80%",
            }}
          >
            Login
          </Button>
        </Paper>
      </form>
      {/* // <div className="bottom_text_container" style={{}}> */}
      <div class="footer-container">
        <label
          className="bottom_text"
          style={{
            fontSize: "1rem",
            opacity: formVisible ? 0 : 1,
            zIndex: formVisible ? 0 : 100,
            fontWeight: 100,
          }}
        >
          Startup especializada en ciencia de datos geospaciales e inteligencia
          artificial aplicada a la sostenibilidad <br />
        </label>

        <Footer
          style={{
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            // left: 0,
            // bottom: 0,
            backgroundColor: "transparent",
            paddingBottom: 0,
            borderTop: "3px solid rgb(var(--color-secondary))",
            borderShadow: "1px 2px black",
            backdropFilter: "blur(5px) brightness(0.75)",
          }}
        />
      </div>
    </div>
  );
}
