 import Notiflix from "notiflix";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OpenLayersFullMap from "../../../OpenLayersMap";
import {get_map_from_geojson_filename, get_graph_data_from_uuid} from "../../services/municipio_service"
import {LAYER_PRE_2002,LAYER_DATE_CONSTRUCTION,LAYER_SOLAR,LAYER_SOLAR_RADIATION, LAYER_SUSCEPTIBILITY, LAYER_CURRENT_USE,LAYER_PUBLIC_AMIANTIC, LAYER_TRANSPARENT } from "../../Objects/Layers/LayerClass"
import OpenLayersBasicMap from "../../forms/CatastroForm/Location/Catastro_Map";
import { useSearchParams } from 'react-router-dom';
import { Paper } from "@mui/material";
import { ColumnChart, DonutChart, LineChart } from "../../services/graph_service";


export const DemoGreenKW = ({ine, with_graphics=false})=>{
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const withGraphicsParam = searchParams.get('withGraphics');
    
    with_graphics = with_graphics | withGraphicsParam;
    console.log("withGraphicsParam", with_graphics, withGraphicsParam)

    if(ine == undefined){
        ine = params.ine;
    }
    const FULL_LAYERS_DATA = {
        "pre2002":LAYER_PRE_2002,
        "dateConstruction":LAYER_DATE_CONSTRUCTION,
        "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
        "currentUse" : LAYER_CURRENT_USE,
        "transparent":LAYER_TRANSPARENT
    
      }
    const DEFAULT_LAYERS_DATA = {
       "solar":LAYER_SOLAR,
       "LAYER_SOLAR_RADIATION":LAYER_SOLAR_RADIATION,
       // "pre2002":LAYER_PRE_2002,
       // "dateConstruction":LAYER_DATE_CONSTRUCTION,
        "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
     //   "currentUse" : LAYER_CURRENT_USE,
        "transparent":LAYER_TRANSPARENT
    }
    const names = {
      "39020":"39020_castro_urdiales_geometry_geojson.geojson",
      "39030":"39030_guriezo_geometry_geojson.geojson",
      "39057":"39057_ramales_de_la_victoria_geometry_geojson.geojson",
      "39058":"39058_rasines_geometry_geojson.geojson",
      "39083":"39083_soba_geometry_geojson.geojson", 
    } 
    const uuids = {
      "39057": "686187f0-d430-4966-884e-579ba48522b8",
      "39083": "e95433f6-9dcb-42a1-8e81-79b5fd244485",
      "39030": "085f2759-ffa3-4019-9c64-65f468d06029",
      "39020": "2a8ec34f-b646-4154-a728-17c4b4be5fd2",
      "39058": "99513e5f-1a4a-4252-b2de-54752a9913cf"
    }
    const uuid = uuids[ine]
    const [dataMap, setDataMap] = useState(false);
    const [amiantoSusceptibilityCategories, setAmiantoSusceptibilityCategories] = useState([]);
    const [buildingAntiqueCategories,       setBuildingAntiqueCategories]       = useState([]);
    const [buildingEvolutionCategories,     setBuildingEvolutionCategories]     = useState([]);
    const [currentUseCategories,            setCurrentUseCategories]            = useState([]);
    const [amiantoSusceptibilityValues,     setAmiantoSusceptibilityValues]     = useState([]);
    const [buildingAntiqueValues,           setBuildingAntiqueValues]           = useState([]);
    const [buildingEvolutionValues,         setBuildingEvolutionValues]         = useState([]);
    const [currentUseValues,                setCurrentUseValues]                = useState([]);
    const [dataGraphics, setDataGraphics] = useState(false);


    const get_layers_to_use = (ine_code)=>{
        // if(uuid == "b6bf91a5-637d-4e96-b6a2-d35b0fb6d1b7"){ // VALLADOLID
        //     return {
        //         "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
        //         "transparent":LAYER_TRANSPARENT
        //     }
        // }else if(uuid="643412c9-56dd-45bf-b4dd-b2df26d1a2a9"){ // Torremolinos
        //     return FULL_LAYERS_DATA
        // }
        return DEFAULT_LAYERS_DATA;
    }

    const load_data_map = async()=>{
      
        const d = await get_map_from_geojson_filename(names[ine]);
        console.log("Geometría obtenida", d)
        if(d==false){
            Notiflix.Notify.failure("Error obteniendo los datos del mapa.")
        }
        setDataMap(JSON.stringify([d]));
        
    }
    function redondearNumeros(arrayNumeros) {
        // Verificar si el primer elemento del array es un número flotante
        if (!Number.isInteger(arrayNumeros[0])) {
            return arrayNumeros.map(numero => parseFloat(numero.toFixed(2)));
        } else {
            // Si el primer número no es flotante, devuelve el array original
            return arrayNumeros;
        }
    }
    const load_graph = async (uuid, type_of_analysis, set_key, set_value) =>{
        let data = await get_graph_data_from_uuid(uuid, type_of_analysis);
       // console.log("Data",type_of_analysis ,data )
        if(data && "keys" in data && "values" in data){
          set_key(data["keys"]);
          set_value(redondearNumeros(data["values"]));
        }
        return data
    }
    const load_graph_building_antique =  async (uuid, type_of_analysis, set_key, set_value) =>{
        let data = await get_graph_data_from_uuid(uuid, type_of_analysis);
       // console.log("Data",type_of_analysis ,data )
        if(data && "keys" in data && "values" in data){
          const index = data["keys"].indexOf("pre-1900");
         // console.log("Se deben reordenar los elementos? ", index,  data["keys"])
          if(index > -1){
           // console.log("Reordenando elementos ", data)
            let element = "pre-1900"
            data["keys"].splice(index, 1); 
            data["keys"].unshift(element);
    
            element = data["values"][index]
            data["values"].splice(index, 1); 
            data["values"].unshift(element);
           // console.log("elementos reordenados ", data)
    
          }
          set_key(data["keys"]);
          set_value(redondearNumeros(data["values"]));
        }
        return data
      }
    const load_data = async()=>{
        Notiflix.Loading.circle();
        const funciones = [load_data_map()];
        if(with_graphics) {
            funciones.concat([
                load_graph(uuid,"current_use", setCurrentUseCategories                      , setCurrentUseValues ),
                load_graph_building_antique(uuid,"building_evo", setBuildingEvolutionCategories              , setBuildingEvolutionValues ),
                load_graph(uuid,"building_antique", setBuildingAntiqueCategories            , setBuildingAntiqueValues ),
                load_graph(uuid,"amianto_susceptibility", setAmiantoSusceptibilityCategories, setAmiantoSusceptibilityValues ),
            ]);
        }
        Promise.all(funciones).then((r)=>Notiflix.Loading.remove()).catch((error) => {
            console.error("Error obteniendo los datos ", error);
        })
        
    }
    useEffect(()=>{
        load_data()
    }, [])


return dataMap ? <div style={{width:"100vw", height:"100vh", overflowX:"hidden"}}>
     <OpenLayersFullMap
     isGetCoordinatesOnClickEnabled={false}
     geojsons={dataMap} // Omitir si no tienes un geojson
     style={{
       height:with_graphics? "90%":"100%",
       width: "100%",
     }} // Ajusta el tamaño a tus necesidades
     layers_data={get_layers_to_use(ine)}
     defaultSelectedLayer={"solar"}
   />
   
   {with_graphics == true &&
          currentUseValues !== undefined &&
          currentUseValues !== false &&
          currentUseValues !== null &&
          currentUseValues != [] && (
            <div
              style={{
                paddingTop: "1rem",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                flexDirection: "row",
                gap: "1.5rem",
                width: "100%",
              }}
            >
              <Paper style={{ width: "100%", flex: "1 1 500px", minHeight: "300px", maxWidth: "700px", padding:"10px"}}>
                <DonutChart categories={amiantoSusceptibilityCategories} series={amiantoSusceptibilityValues} title={"Susceptibilidad de las edificaciones de contener amianto."}/>
              </Paper>
              <Paper style={{ width: "100%", flex: "1 1 500px", minHeight: "300px", maxWidth: "700px", padding:"10px"}}>
                <ColumnChart categories={buildingAntiqueCategories} series={buildingAntiqueValues} title={"Edad media de los edificios por tipología de uso"} />
              </Paper>
              <Paper style={{ width: "100%", flex: "1 1 500px", minHeight: "300px", maxWidth: "700px", padding:"10px" }} >
                <LineChart categories={buildingEvolutionCategories} series={buildingEvolutionValues} title={"Evolución del desarrollo urbano. Edificaciones por décadas."} />
              </Paper>
              <Paper style={{width: "100%",flex: "1 1 500px",minHeight: "300px",maxWidth: "700px",padding:"10px"}}>
                <ColumnChart isHorizontal={false} colors={["#66c2a5","#fc8d62","#8da0cb","#e78ac3","#a6d854","#ffd92f","#e5c494"]} categories={currentUseCategories} series={currentUseValues} title={"Cantidad de edificaciones por tipología de uso."}/>
              </Paper>
            </div>
          )}
   </div>:<OpenLayersBasicMap
    style={{
        height:"100%",
        width: "100%",
    }}
    />

//     return <div style={{width:"100%", height:"100vh", overflowY:"hidden"}}>
//         <iframe
//     src={get_link_from_municipe(municipe)}
//     rameborder="0"
//     style={{ width: "100%", height: "100%", border: 0 }}
//     allowFullScreen
//   ></iframe></div>
}
export default DemoGreenKW;