
import { renderToString } from 'react-dom/server'
import TableKeyValue from '../../../commons/TableKeyValue';
import { Paper, TextField } from '@mui/material';
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"

function interpolateColors(color1, color2, steps) {
  var stepFactor = 1 / (steps - 1),
    interpolatedColorArray = [];

  color1 = color1.match(/\d+/g).map(Number);
  color2 = color2.match(/\d+/g).map(Number);

  for (var i = 0; i < steps; i++) {
    interpolatedColorArray.push(
      interpolateColorFactor(color1, color2, stepFactor * i)
    );
  }

  return interpolatedColorArray;
}
function interpolateColorFactor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  var result = color1.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}
const legendBoxes = (values) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {values.map((value) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "0.8rem",
                height: "0.8rem",
                backgroundColor: value.color,
                borderRadius: "50%",
              }}
            ></div>
            <div
              style={{
                width: "0.8rem",
              }}
            />
            <label>{value.text}</label>
          </div>
        );
      })}
    </div>
  );
};


const legendGradient = (
  topText,
  bottomText,
  gradientColors,
  centerText = ""
) => {
  const gradientStyle = {
    background: `linear-gradient(${gradientColors.join(", ")})`,
    //background: "rgb(0,0,255)",
    //background: "linear-gradient(0deg, rgba(0,0,255,1) 0%, rgba(9,9,121,1) 0%, rgba(0,0,255,1) 5%, rgba(0,255,0,1) 95%, rgba(0,255,0,1) 100%);",
    opacity: 0.9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px", // Ajusta según la necesidad
    width: "40px", // Ajusta según la necesidad
  };
  return (
    <div className="legend">
      <div className="legend-text top-text">{topText}</div>
      <div className="legend-container">
        <div style={gradientStyle}>
          <div className="legend-text">{centerText}</div>
        </div>
      </div>
      <div className="legend-text bottom-text">{bottomText}</div>
    </div>
  );
};
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

const tooltipBeginning = (feature) => {
  if (
    feature &&
    "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "beginning" in feature.values_.features[0].values_
  ) {
    return (
      "Fecha de construcción: " +
      feature.values_.features[0].values_["beginning"]
    );
  }
};

var info_field = (
  label,
  _data,
  fixed_decimals = false,
  is_date=false,
  backgroundColor = false,
  suffix = "",
) => {
    
 // console.log(_data);
  if (fixed_decimals && _data !== undefined && _data !== null) {
    //_data = _data.toFixed(fixed_decimals).toString();
    _data = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,

    }).format(_data).toString();
  }
  if(is_date  && _data !== undefined && _data !== null){
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  return (

    <LayerPublicAmianticTextInput
      style={{
        width: "90%",
        paddingLeft:"5%",
       // marginTop: "0.8rem",
        backgroundColor: backgroundColor,
      }}
     // sx={{style:{width:"100%"}}}
     // size="small"
      label={label}
      value={"" + _data + suffix}
      //readonly
    />
  );
};
// var info_field = (
//   label,
//   _data,
//   fixed_decimals = false,
//   is_date=false,
//   backgroundColor = false
// ) => {
//  // console.log(_data);
//   if (fixed_decimals && _data !== undefined && _data !== null) {
//     _data = _data.toFixed(fixed_decimals).toString();
//   }
//   if(is_date  && _data !== undefined && _data !== null){
//     _data = new Date(_data).toISOString().slice(0, 10);
//   }
//   return (
//     <TextField
//       style={{
//         width: "100%",
//         marginTop: "0.8rem",
//         backgroundColor: backgroundColor,
//       }}
//       size="small"
//       label={label}
//       value={_data}
//       readonly
//     />
//   );
// };

const fullModalInfo = (feature)=>{
  console.log("fullModalInfo feature", feature)
  if (
    feature &&
    "values_" in feature &&
    "beginning" in feature["values_"] && 
    "Provincia" in feature["values_"]
  ){
        
    const data = feature.values_;
    let data_processed = JSON.parse(JSON.stringify(data));
    // const replacements = {"Clave": "Clave",
    // "Calendario": "Calendario",
    // "Codigo": "Codigo",
    // "Link": "Link",
    // "Municipio": "Municipio",
    // "Provincia": "Provincia",
    // "a_total": "Area",
    // "antiguedad": "antiguedad",
    // "beginning": "beginning",
    // "cat_antigu": "cat_antigu",
    // "cat_area": "cat_area",
    // "centroid": "centroid",
    // "currentUse": "currentUse",
    // "end": "end",
    // "floorAbove": "floorAbove",
    // "info_sys": "info_sys",
    // "lic_new_ba": "lic_new_ba",
    // "lic_new_co": "lic_new_co",
    // "mano_cost": "mano_cost",
    // "new_basicC": "new_basicC",
    // "new_coverC": "new_coverC",
    // "priority_r": "priority_r",
    // "projection": "projection",
    // "ref_geo": "ref_geo",
    // "reference": "reference",
    // "reti_cost": "reti_cost",
    
    // "risk_norm": "risk_norm",
    // "sourceStat": "sourceStat",
    // "sum_factor": "sum_factor",
    // "val_antigu": "val_antigu",
    // "val_area": "val_area",
    // "val_use": "val_use",
    // "value_uom": "value_uom"}
    
    const replacements = {
      "Provincia":"Provincia",
      "Municipio":"Municipio",
      "reference":"Ref. Catastral",
      "currentUse":"Uso actual del edificio",
      "beginning":"Fecha de construcción",
      "a_total": "Area",
      "Link_catastro":"Catastro online",
     // "Link":"Imagen de catastro",
      "risk_level":"Riesgo de contener amianto"
     //"Superficie de amianto":

    }
    const is_float = ["a_total"]
    const is_date = []
    for(const key of Object.keys(data)){
      if(!Object.keys(replacements).includes(key)){
        delete data_processed[key];
      }
    }
    // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
     const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
    const rc1 = data_processed["reference"].substring(0, 7);
    const rc2 = data_processed["reference"].substring(7, 14);
    const catastroUrl = uri + rc1 + "&rc2=" + rc2;
    //   properties.put("urlIframe", catastroUrl);
    // }
    const catastroImageUrl = "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
    data_processed["Link_catastro"] = catastroUrl;
    data_processed["Link_catastro"] = catastroUrl;
    
    return <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
      <div style={{display:"flex", flexDirection:"column", flex:"1 1 220px"}}>
        {Object.keys(replacements).map(key=>{
          
          return info_field(replacements[key],data_processed[key] , is_float.includes(key), is_date.includes(key))
        })}
      </div>
      <div  style={{display:"flex", flex:"3 1 320px", height:"500px"}}>
       <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
      </div>
    </div>
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
  }
  return false;
}

export default class LayerClass {
  constructor(label, legend, colorBgFeatureFunction, getTooltip = ()=>"", getModalBody = fullModalInfo) {
    this.label = label;
    this.legend = legend;
    this.colorBgFeatureFunction = colorBgFeatureFunction;
    this.getTooltip = getTooltip;
    this.getModalBody = getModalBody;
  }
}
export {interpolateColors,legendBoxes,  legendGradient, hexToRgb, tooltipBeginning}