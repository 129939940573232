import React, { createContext, useEffect, useState } from "react";
import App from "./App";

const AppContext = createContext({});

export function AppContextProvider() {
  let localStyle = { languages: ["ES", "EN"], baseColor1: "#000000" };
  const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  const LOCAL_STORAGE_DATA = localStorage.getItem(LOCAL_STORAGE_KEY);
  let LOCAL_STORAGE_TOKEN = "";
  //console.log("Local storage data", LOCAL_STORAGE_DATA)
  let data = {};
  let rol = {};

  if (LOCAL_STORAGE_DATA != null) {
    try {
      data = JSON.parse(LOCAL_STORAGE_DATA);

      if (!("token" in data)) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      } else {
        LOCAL_STORAGE_TOKEN = data["token"];
      }
      // if ("rol" in data) {
      //   rol = data["rol"];
      // }
    } catch (err) {
     // console.log("Local storage cleaned");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }
  const [token, setToken] = useState(LOCAL_STORAGE_TOKEN);
  let email = "";
  try {
    let payload = JSON.parse(LOCAL_STORAGE_TOKEN);
    email = payload["email"];
  } catch (err) {}
  const [userHeader, setUserHeader] = useState(email);

  const [roles, setRoles] = useState(rol);
  return (
    <AppContext.Provider
      value={{ localStyle, token, setToken, roles, setRoles, userHeader, setUserHeader }}
    >
      <App />
    </AppContext.Provider>
  );
}

export default AppContext;
