import React, { useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import "./Main.css";
import Header from "../../../HeaderV2";
import { Paper } from "@mui/material";
import en from "./i18n/en";
import es from "./i18n/es";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import CatastroForm from "../../forms/CatastroForm/CatastroForm";
import Footer from "../../../Footer";
import { FaAngleUp } from "react-icons/fa";

i18next.addResourceBundle("en", "MAIN", en);
i18next.addResourceBundle("es", "MAIN", es);

const Main = () => {
  const { t } = useTranslation("MAIN");
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="background_map">
      <Header
        style={{
          backgroundColor: "rgb(var(--color-primary))",
        }}
        showHelpButton
      ></Header>
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "100vw",
          minHeight: "calc(100vh - 12rem - var(--header-height)",
        }}
      >
        {/* <article style={{ display: "flex", justifyContent: "center" }}>
          <Paper className="main_general_description_box">
            <label className="main_general_description_title">
              {t("txt_description_title")}
            </label>
            <label className="main_general_description">
              {t("txt_description")}
            </label>
          </Paper>
        </article> */}
        <article
          style={{ display: "flex", justifyContent: "center" }}
          className="background_map"
        >
          <Paper
            style={{ backgroundColor: "var(--color-third)" }}
            className="main_general_description_box "
          >
            <CatastroForm></CatastroForm>
          </Paper>
        </article>
      </div>
      {/* <div className="top-to-btm">
        {" "}
        {showTopBtn && (
          <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
        )}{" "}
      </div> */}
      {showTopBtn && <ScrollToTop onClick={goToTop} />}
      <Footer></Footer>
    </div>
  );
};

export default Main;
