import React from "react";
import { FaAngleUp } from "react-icons/fa";

import "../../../index.css";

const ScrollToTop = ({ onClick }) => {
  return (
    <div className="top-to-btm">
      <FaAngleUp onClick={onClick} className="icon-position icon-style" />
    </div>
  );
};
export default ScrollToTop;
