const locale = {
  CATASTRO_FORM_STEP_0: "Información",
  CATASTRO_FORM_STEP_1: "Ubicación",
  CATASTRO_FORM_STEP_2: "Analítica",
  CATASTRO_FORM_STEP_3: "Resultado",
  catastro_Next: "Siguiente",
  catastro_Finish: "Terminar",
  catastro_Back: "Atrás",
  catastro_Reset: "Ir al inicio",
  catastro_Completed:
    "Análisis municipal terminado ¡Esperamos que haya disfrutado del servicio de Agforest <b>asbestos detection</b>!",
  catastro_label_location_search_box: "Escribe o busca en el listado:",
};
export default locale;
