const locale = {
  CATASTRO_FORM_STEP_0: "Información",
  CATASTRO_FORM_STEP_1: "Ubicación",
  CATASTRO_FORM_STEP_2: "Personalización",
  CATASTRO_FORM_STEP_3: "Resultado",
  Next: "Siguiente",
  Finish: "Terminar",
  Back: "Atrás",
  Completed:
    "Análisis provincial terminado ¡Esperamos que haya disfrutado del servicio!",
};
export default locale;
