import "./Catastro_Map.css";
import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Style, Stroke, Fill } from "ol/style";
import { Overlay } from "ol";
import XYZ from "ol/source/XYZ";

const OpenLayersMap = ({
  geojson,
  center,
  tooltip_text = "",
  style,
  strokeColor,
  strokeWidth,
  fillColor = "0, 0, 255",
  fillOpacity = "0.4",
}) => {
  const mapRef = useRef();
  const tooltipRef = useRef();

  // const baseLayer = new TileLayer({
  //   source: new OSM(),
  // });
  const styles = [
    "light_all",
    "dark_all",
    "light_nolabels",
    "light_only_labels",
    "dark_nolabels",
    "dark_only_labels",
    "rastertiles/voyager",
    "rastertiles/voyager_nolabels",
    "rastertiles/voyager_only_labels",
    "rastertiles/voyager_labels_unde",
  ];
  const baseLayer = new TileLayer({
    source: new XYZ({
      url: "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
    }),
  });
  let vectorLayer;
  // if (mapRef.current) {
  //   mapRef.current.innerHTML = "";
  // }
  let map = null;

  useEffect(() => {
    // Creación de la capa de mapa base usando OSM (Open Street Map)
    // Creación de la capa vectorial usando los datos GeoJSON, si se proporcionan
    const tooltip = new Overlay({
      element: tooltipRef.current,
      positioning: "bottom-center",
      stopEvent: false,
      offset: [0, -10],
    });
    if (geojson) {
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
      }
      const geojsonObject = geojson;
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject),
      });

      vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: strokeColor || "#000000",
            width: strokeWidth || 2,
          }),
          fill: new Fill({
            color: `rgba(${fillColor || "0, 0, 0"}, ${fillOpacity || 1})`,
          }),
        }),
      });

      let map = new Map({
        target: mapRef.current,
        overlays: [tooltip],
        layers: [baseLayer, ...(vectorLayer ? [vectorLayer] : [])],
        view: new View({
          center: center || [-3.70256, 40.4165], // Si no se proporciona un centro, se centra en Madrid
          projection: "EPSG:4326",
          zoom: center ? 10 : 5, // Si no se proporciona un centro, el zoom es menor para mostrar más territorio
        }),
      });
      // map.setLayers([baseLayer, ...(vectorLayer ? [vectorLayer] : [])]);
      // map.setView(
      //   new View({
      //     center: center || [-3.70379, 40.416775], // Si no se proporciona un centro, se centra en Madrid
      //     zoom: center ? 10 : 5, // Si no se proporciona un centro, el zoom es menor para mostrar más territorio
      //   })
      // );

      // Si se proporciona un geojson, ajusta la vista para que se muestre en el mapa.
      map.getView().fit(vectorSource.getExtent(), {
        padding: [100, 100, 100, 100],
      });
      let actual_zoom = map.getView().getZoom();
      if (actual_zoom < 5) {
        map.setView(map.getView().setZoom(50));
      }
      map.on("pointermove", function (e) {
        const pixel = map.getEventPixel(e.originalEvent);
        const hit = map.hasFeatureAtPixel(pixel);

        map.getTargetElement().style.cursor = hit ? "pointer" : "";
        tooltipRef.current.innerHTML = hit ? tooltip_text : ""; // Aquí puedes poner la información que quieras mostrar
        tooltip.setPosition(hit ? e.coordinate : undefined);
      });
    } else {
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
      }
      map = new Map({
        target: mapRef.current,
        layers: [baseLayer, ...(vectorLayer ? [vectorLayer] : [])],
        view: new View({
          center: [-3.70256, 40.4165], // Si no se proporciona un centro, se centra en Madrid
          projection: "EPSG:4326",
          zoom: center ? 10 : 5, // Si no se proporciona un centro, el zoom es menor para mostrar más territorio
        }),
      });
    }
    return () => map?.setTarget(undefined);
  }, [geojson, center, tooltip_text]);

  // return <div ref={mapRef} style={style} />;
  return (
    <div style={style}>
      <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
      <div ref={tooltipRef} className="tooltip" />
    </div>
  );
};

export default OpenLayersMap;
