import React from "react";
import "./ExampleMap.css";
import Header from "../../../HeaderV2";
import Footer from "../../../Footer";

const ExampleMap = () => {
  return (
    <div className="background_map">
      <Header
        style={{
          backgroundColor: "rgb(var(--color-primary))",
        }}
        showHelpButton
      ></Header>
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "100vw",
          minHeight: "calc(100vh - 12rem - var(--header-height)",
        }}
      >
        <iframe
          //src="https://geographer1.maps.arcgis.com/apps/instant/sidebar/index.html?appid=cb11f0a92eb94f58867132f2fe925a6b"
          src="https://www.asbestos.ai/public/Valladolid"
          // width="400"
          // height="600"
          frameborder="0"
          style={{ width: "100vw", height: "80vh", border: 0 }}
          allowfullscreen
        >
          No se admiten iFrames en esta página.
        </iframe>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ExampleMap;
