
import * as React  from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {  TextField } from "@mui/material";

var info_field = (
  label,
  _data,
  fixed_decimals = false,
  is_date=false,
  backgroundColor = false,
  suffix = "",
  full_data = {},
  key_state = "",
  setFullData = ()=>{},
  key_states = {}
) => {
  console.log("Suffix3", suffix)
  _data = key_states[key_state]["value"];
  if (fixed_decimals && _data !== undefined && _data !== null) {
    //_data = _data.toFixed(fixed_decimals).toString();
    _data = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,

    }).format(_data).toString();
  }
  if(is_date  && _data !== undefined && _data !== null){
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  if(!_data || _data == "undefined" || _data== undefined){
    suffix = "";
  }
  console.log("Info field:",key_states,  key_state, _data, fixed_decimals, suffix);
  //console.log("Info field2:",label,  key_states[key_state], key_states[key_state]["value"]);

  return (
<>
    <TextField
      style={{
        width: "100%",
        marginTop: "0.8rem",
      //  backgroundColor: backgroundColor,
      }}
     // sx={{style:{width:"100%"}}}
     // size="small"
      label={label}
      value={"" + _data+suffix}
      
      // onChange={_value => {
      //     console.log("On change", _value.target.value, full_data)
      //     let d = full_data;
      //     setFullData({...full_data, [key_state]:_value.target.value})
      //     key_states[key_state]["set"]( _value.target.value)

      // }}
      //readonly
    />
    <br/>
    </>
  );
};

const SolarInfoTabComponent = ({feature})=>{
  const [data_processed, set_data_processed] = React.useState( JSON.parse(JSON.stringify(feature.values_)))
  data_processed["coste_total"] = data_processed["Coste €/kWp"] * data_processed["Potencia_instalada_kWp"]

  const [a_total, set_a_total] = React.useState(data_processed["a_total"])
  const [superficie_fv, set_superficie_fv] = React.useState(data_processed["superficie_fv"])
  const [superficie_cubierta, set_superficie_cubierta] = React.useState(data_processed["superficie_cubierta"])
  const [coste_total, set_coste_total] = React.useState(data_processed["coste_total"])
  const [Potencia_instalada_kWp, set_Potencia_instalada_kWp] = React.useState(data_processed["Potencia_instalada_kWp"])
  const [horizontal_irradiation_mean, set_horizontal_irradiation_mean] = React.useState(data_processed["horizontal_irradiation_mean"])
  const [direct_normal_irradiation_mean, set_direct_normal_irradiation_mean] = React.useState(data_processed["direct_normal_irradiation_mean"])
  const [optimal_angle_irradiation_mean, set_optimal_angle_irradiation_mean] = React.useState(data_processed["optimal_angle_irradiation_mean"])
  const [ratio_kWp_m2, set_ratio_kWp_m2] = React.useState(data_processed["ratio_kWp_m2"])
  const [Coste, set_Coste] = React.useState(data_processed["Coste €/kWp" ])
  const [n_modulos, set_n_modulos] = React.useState(data_processed["n_modulos"])
  const [potencia_modulos, set_potencia_modulos] = React.useState(data_processed["potencia_modulos"])
  const [Lucernario, set_Lucernario] = React.useState(data_processed["Lucernario"])
  const [temperature_mean, set_temperature_mean] = React.useState(data_processed["temperature_mean"])

  const [Provincia, set_Provincia] = React.useState(data_processed["Provincia"]);
  const [Municipio, set_Municipio] = React.useState(data_processed["Municipio"]);
  const [reference, set_reference] = React.useState(data_processed["reference"]);
  const [currentUse, set_currentUse] = React.useState(data_processed["currentUse"]);
  const [beginning, set_beginning] = React.useState(data_processed["beginning"]);
  const [Link_catastro, set_Link_catastro] = React.useState(data_processed["Link_catastro"]);

  const [catastroUrl, setCatastroUrl] = React.useState("")
  const [catastroImageUrl, setCatastroImageUrl] = React.useState("")

  let key_states =  {
    "a_total" : {"value":a_total, "set":set_a_total},
    "superficie_fv" : {"value":superficie_fv, "set":set_superficie_fv},
    "superficie_cubierta" : {"value":superficie_cubierta, "set":set_superficie_cubierta},
    "coste_total" : {"value":coste_total, "set":set_coste_total},
    "Potencia_instalada_kWp" : {"value":Potencia_instalada_kWp, "set":set_Potencia_instalada_kWp},
    "horizontal_irradiation_mean" : {"value":horizontal_irradiation_mean, "set":set_horizontal_irradiation_mean},
    "direct_normal_irradiation_mean" : {"value":direct_normal_irradiation_mean, "set":set_direct_normal_irradiation_mean},
    "optimal_angle_irradiation_mean" : {"value":optimal_angle_irradiation_mean, "set":set_optimal_angle_irradiation_mean},
    "ratio_kWp_m2" : {"value":ratio_kWp_m2, "set":set_ratio_kWp_m2},
    "Coste €/kWp" : {"value":Coste, "set":set_Coste},
    "n_modulos" : {"value":n_modulos, "set":set_n_modulos},
    "potencia_modulos" : {"value":potencia_modulos, "set":set_potencia_modulos},
    "Lucernario" : {"value":Lucernario, "set":set_Lucernario},
    "temperature_mean" : {"value":temperature_mean, "set":set_temperature_mean},

    "Provincia" : {"value":Provincia, "set":Provincia},
    "Municipio" : {"value":Municipio, "set":Municipio},
    "reference" : {"value":reference, "set":reference},
    "currentUse" : {"value":currentUse, "set":currentUse},
    "beginning" : {"value":beginning, "set":beginning},
    "Link_catastro" : {"value":Link_catastro, "set":Link_catastro}
  }
 // const [replacements, setReplacements] = React.useState({})
 const replacements = {
  "Provincia":"Provincia",
  "Municipio":"Municipio",
  "reference":"Ref. Catastral",
  "currentUse":"Uso actual del edificio",
  "beginning":"Fecha de construcción",
  //"age":"Antigüedad",
  "a_total": "Área",
  "Link_catastro":"Catastro online",
  //"Link_image":"Imagen del inmueble",
  //"area_amianto":"Superficie de amianto",
  //"amianto_percentage":"Porcentaje de ocupación de amianto",
  //"priority":"Prioridad de retirada",
  //"date_withdrawal":"Calendario de retirada",
  //"withdrawal_cost":"Coste de retirada",
  //"labour_cost":"Coste de la mano de obra",
  //"new_cover_cost":"Coste de nueva cubierta",
  //"building_license_cost":"Coste de licencia de obra",
  //"new_basic_cover_cost":"Coste de nueva cubierta básica",
  //"basic_building_license_cost":"Coste de licencia de obra básica",
  //"made_by":"Análisis realizado por",

 //"Superficie de amianto":
}


const replacements_sun = {
  //"horizontal_irradiation_year"
  "a_total": "Área",
  "superficie_fv":"Porcentaje de superficie con fotovoltaicas",
  "superficie_cubierta":"Superficie disponible considerada para el cálculo",
  "coste_total":"Coste total estimado",
  "Potencia_instalada_kWp":"Potencia total calculada",
  "horizontal_irradiation_mean":"Radiación anual média - Horizontal",
  "direct_normal_irradiation_mean" : "Radiación anual média - Directa normal",
  "optimal_angle_irradiation_mean": "Radiación anual média - Ángulo óptimo",
  "ratio_kWp_m2":"Ratio kWp/m²",
  "Coste €/kWp":"Coste por kWp",
  "n_modulos": "Número de módulos",
  "potencia_modulos":"Potencia de cada módulo",
  "Lucernario":"¿Consideramos que tiene lucernarias?",
  "temperature_mean" : "Temperatura media"


}
 // const [replacements_sun, setReplacements_sun] = React.useState({})

  const links = [
    "Link_catastro",
    "Link_image"
  ]
  const units = {
    "ratio_kWp_m2":" kWp/m²",
    "horizontal_irradiation_mean":" KWh/m²",
    "potencia_modulos":" KWp",
    "coste_total":" €",
    "temperature_mean":" ºC",
    //"Potencia de cada módulo":" KWp",
    "Potencia_instalada_kWp":" kWp",
    "Coste €/kWp":" €/kWp",
    "direct_normal_irradiation_mean":" KWh/m²",
    "optimal_angle_irradiation_mean":" KWh/m²",
    "superficie_fv": " %",
    "a_total": " m²",
    "superficie_cubierta": " m²",
    "area_amianto":" m²",
    "age":" años",
    "amianto_percentage":" %",
    "withdrawal_cost": " €",
    "labour_cost": " €",
    "new_cover_cost": " €",
    "building_license_cost": " €",
    "new_basic_cover_cost": " €",
    "basic_building_license_cost": " €"
  }
  const is_float = [
    "coste_total",
    "horizontal_irradiation_mean",
    "temperature_mean",
    "direct_normal_irradiation_mean",
    "optimal_angle_irradiation_mean",
    "a_total", 
    "amianto_percentage",
    "area_amianto",
    "withdrawal_cost",
    "labour_cost",
    "new_cover_cost",
    "building_license_cost",
    "new_basic_cover_cost",
    "basic_building_license_cost"]
  const is_date = []


  React.useEffect(()=>{
    
    const data = feature.values_;
    console.log("Feasture values : ", data)
    let data_processed_current = JSON.parse(JSON.stringify(data));
    set_data_processed(JSON.parse(JSON.stringify(data)));
    set_a_total(data_processed_current["a_total"]);
   
    let data_processed_temp = data_processed_current
    for(const key of Object.keys(data)){
      if(!Object.keys(replacements).includes(key) && !Object.keys(replacements_sun).includes(key)){
        delete data_processed_temp[key];

      }
    }
    set_data_processed(data_processed_temp)
    data_processed_current = data_processed_temp;
    // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
     const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
    let catastroUrl = "";
    setCatastroImageUrl("");
    if(Object.keys(data).includes("Link_catastro")){
      setCatastroUrl(data["Link_catastro"])
    }else if(Object.keys(data).includes("reference")){
      const rc1 = data["reference"].substring(0, 7);
      const rc2 = data["reference"].substring(7, 14);
      setCatastroUrl(uri + rc1 + "&rc2=" + rc2);

      //data_processed["Link_catastro"] = uri + rc1 + "&rc2=" + rc2;
      set_data_processed({...data_processed_current, "Link_catastro": uri + rc1 + "&rc2=" + rc2});
    }
    if(Object.keys(data).includes("reference")){
      setCatastroImageUrl("https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"]);
    }
    //   properties.put("urlIframe", catastroUrl);
    // }
   
    console.log("data_processed_current", data_processed_current)
    //this.forceUpdate();
   
  }, [feature])
  const [value, setValue] = React.useState('1');
   
   React.useEffect(() => {
  //   if(value == "1"){
  //     setValue("2")
  //   }else{
  //     setValue("1")
  //   }
  set_a_total(data_processed["a_total"])
  set_superficie_fv(data_processed["superficie_fv"])
  set_superficie_cubierta(data_processed["superficie_cubierta"])
  set_coste_total(data_processed["coste_total"])
  set_Potencia_instalada_kWp(data_processed["Potencia_instalada_kWp"])
  set_horizontal_irradiation_mean(data_processed["horizontal_irradiation_mean"])
  set_direct_normal_irradiation_mean(data_processed["direct_normal_irradiation_mean"])
  set_optimal_angle_irradiation_mean(data_processed["optimal_angle_irradiation_mean"])
  set_ratio_kWp_m2(data_processed["ratio_kWp_m2"])
  set_Coste(data_processed["Coste €/kWp"])
  set_n_modulos(data_processed["n_modulos"])
  set_potencia_modulos(data_processed["potencia_modulos"])
  set_Lucernario(data_processed["Lucernario"])
  set_temperature_mean(data_processed["temperature_mean"])

  set_Provincia(data_processed["Provincia"])
  set_Municipio(data_processed["Municipio"])
  set_reference(data_processed["reference"])
  set_currentUse(data_processed["currentUse"])
  set_beginning(data_processed["beginning"])
  set_Link_catastro(data_processed["Link_catastro"])
  key_states =  {
    "a_total" : {"value":a_total, "set":set_a_total},
    "superficie_fv" : {"value":superficie_fv, "set":set_superficie_fv},
    "superficie_cubierta" : {"value":superficie_cubierta, "set":set_superficie_cubierta},
    "coste_total" : {"value":coste_total, "set":set_coste_total},
    "Potencia_instalada_kWp" : {"value":Potencia_instalada_kWp, "set":set_Potencia_instalada_kWp},
    "horizontal_irradiation_mean" : {"value":horizontal_irradiation_mean, "set":set_horizontal_irradiation_mean},
    "direct_normal_irradiation_mean" : {"value":direct_normal_irradiation_mean, "set":set_direct_normal_irradiation_mean},
    "optimal_angle_irradiation_mean" : {"value":optimal_angle_irradiation_mean, "set":set_optimal_angle_irradiation_mean},
    "ratio_kWp_m2" : {"value":ratio_kWp_m2, "set":set_ratio_kWp_m2},
    "Coste €/kWp" : {"value":Coste, "set":set_Coste},
    "n_modulos" : {"value":n_modulos, "set":set_n_modulos},
    "potencia_modulos" : {"value":potencia_modulos, "set":set_potencia_modulos},
    "Lucernario" : {"value":Lucernario, "set":set_Lucernario},
    "temperature_mean" : {"value":temperature_mean, "set":set_temperature_mean},

    "Provincia" : {"value":Provincia, "set":set_Provincia},
    "Municipio" : {"value":Municipio, "set":set_Municipio},
    "reference" : {"value":reference, "set":set_reference},
    "currentUse" : {"value":currentUse, "set":set_currentUse},
    "beginning" : {"value":beginning, "set":set_beginning},
    "Link_catastro" : {"value":Link_catastro, "set":set_Link_catastro}
  }

  }, [data_processed])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // React.useEffect(() => {
  //   console.log("Abriendo modal?", data_processed)
  //   setController(controller+1);
  // }, [replacements_sun, data_processed, units, replacements, info_field, is_float, is_date])

    return feature ? <div style={{display:"flex",  flexDirection:"column", flex:"1 1 550px", maxHeight:"70vh", overflowY:"auto", paddingLeft:"1rem", paddingTop:"1rem"}}>    <TabContext value={value} >
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
     <TabList onChange={handleChange} aria-label="Información de la localización">
      <Tab label="Información solar" value="1" />
      <Tab label="General" value="2" />
    </TabList>
  </Box>
  <TabPanel value="1"><div>
    {/* {Object.keys(data_processed).map(key=>{
      if(Object.keys(replacements_sun).includes(key)){
        let value_d = data_processed[key];
        let suffix = "";
        if(Object.keys(units).includes(key)){
          suffix=units[key];
        }
        if(key == "a_total"){
          return info_field(replacements_sun[key], value_d , is_float.includes(key), is_date.includes(key), false, suffix);

        }
        return info_field(replacements_sun[key], value_d , is_float.includes(key), is_date.includes(key), false, suffix);
      }
    })} */}
             {feature ? Object.keys(replacements_sun).map(key=>{
              let value_d = data_processed[key];
              let suffix = "";
              console.log("Comprobando units", Object.keys(units), key, Object.keys(units).includes(key))

              if(Object.keys(units).includes(key)){
                console.log("Suffix", units[key])
                suffix=units[key];
              }
              console.log("Suffix2", suffix)

              return info_field(replacements_sun[key], value_d , is_float.includes(key), is_date.includes(key), false, suffix, data_processed, key, set_data_processed, key_states)

            }):<></>} 
          </div></TabPanel>
  <TabPanel value="2"><div>
            {feature ? Object.keys(replacements).map(key=>{
              let value_d = data_processed[key];
              let suffix = "";
              if(Object.keys(units).includes(key)){
                suffix=units[key];
              }
              return info_field(replacements[key], value_d , is_float.includes(key), is_date.includes(key), false, suffix ,data_processed, key, set_data_processed, key_states)
            }):<></>}
          </div></TabPanel>
</TabContext></div>:<></>
}

export default SolarInfoTabComponent;