import { Paper } from "@mui/material";
import React, { useState } from "react";
import YouTube from "react-youtube";

export default function Description() {
  const [textIsClicked, settextIsClicked] = useState(false);
  const videoId = "vFRT9D49o84";
  const url = "https://www.youtube.com/embed/" + videoId;
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
      <div
        style={{
          flex: "2 2 150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          width: "100%",
          maxWidth: "90vw",
        }}
      >
        <YouTube
          opts={videoOptions}
          videoId={videoId}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "90vw",

            paddingTop: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        {/* <iframe
          style={{
            width: "100%",
            height: "100%",
            minWidth: "300px",
            minHeight: "240px",
          }}
          src=url
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
      </div>
      <Paper
        sx={{
          flex: "1 1 330px",
          boxShadow: "3px 3px 8px var(--color-third)",
          padding: "1rem",
          textAlign: "left",
          cursor: "pointer",
          transform: textIsClicked ? "scale(1.3)" : "",
          transition: "all .4s ease-in-out",
          zIndex: "999",
        }}
        onClick={() => {
          settextIsClicked(!textIsClicked);
        }}
      >
        <p>Proporcionamos la soluci&oacute;n completa para cumplir la ley:</p>
        <ol>
          <li>
            Censo de instalaciones con amianto a trav&eacute;s tecnolog&iacute;a
            geoespacial para localizaci&oacute;n
          </li>
          <li>Integraci&oacute;n con catastro</li>
          <li>Calendario de retirada basado en norma UNE 171370-2</li>
          <li>Aplicaci&oacute;n para buz&oacute;n de notificaciones WEB</li>
          <li>
            Software de gesti&oacute;n del ciclo completo de censo de
            amianto:&nbsp;
          </li>
          <ul>
            <li>Detecci&oacute;n de instalaciones</li>
            <li>Gesti&oacute;n de inspecciones</li>
            <li>muestras de laboratorio</li>
            <li>Gesti&oacute;n de retirada por empresas RERA</li>
            <li>Visor de instalaciones&nbsp;</li>
            <li>Informes de control y seguimiento</li>
          </ul>
        </ol>
      </Paper>
    </div>
  );
}
